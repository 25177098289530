<template>
    <div class="stock-check">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>库存管理</el-breadcrumb-item>
            <el-breadcrumb-item>库存盘点</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item v-if="showDel&&modifyRule">
                    <el-button @click="deleteList" 
                    type="danger" size="small" icon="el-icon-delete-solid" round>删除</el-button>
                </el-form-item>
                <el-form-item >
                     <el-date-picker
                    v-model="timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item >
                    <el-select v-model="wid" clearable placeholder="选择仓库">
                        <el-option
                        v-for="item in warehouseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-table -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbclick"
            style="width: 100%">
                <!-- <el-table-column
                    type="selection"
                    width="55">
                </el-table-column> -->
                <el-table-column
                    prop="time"
                    label="时间">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="pid"
                    label="盘点编号">
                </el-table-column>
                <el-table-column
                    prop="wname"
                    label="仓库名称">
                </el-table-column>
                <el-table-column
                    prop="wid"
                    label="仓库编号">
                </el-table-column>
                <el-table-column
                    prop="checker"
                    label="盘点人">
                </el-table-column>
                <el-table-column
                    prop="reviewer"
                    label="复核人">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="editRule"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-view" @click="view(scope.row)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.pid)"></i>
                        <!-- <i class="edit el-icon-download" @click="download(scope.row)"></i> -->
                        <el-dropdown @command="downloadCommand">
                        <span class="el-dropdown-link">
                            下载<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="beforeCommand(scope.row,false)">盘点明细-不带图片</el-dropdown-item>
                            <el-dropdown-item :command="beforeCommand(scope.row,true)">盘点明细-带图片</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="block">
            <el-pagination
                 @current-change="changePage"
                layout="prev, pager, next"
                :page-size="pageSize"
                :total="totalPage">
            </el-pagination>
        </div>
        <!-- drawer -->
        <el-dialog
        :title="currentData.name"
        :model="false"
        center
        :visible.sync="showPop"
        :size="size">
        <StockDetail :data="currentData" />
        </el-dialog>
    </div>
</template>
<script>
import {checkRules,formatTime} from '@/utils/util.js'
import StockDetail from '@/components/StockDetail'
export default {
    components:{
        StockDetail
    },
    data(){
        return{
            wid:"",
            warehouseList:[],
            currentData:{},
            showPop:false,
            list:[],
            size:"50%",
            showDel:false,
            modifyRule:checkRules(12),//添加权限
            addRule:checkRules(12),//添加权限
            editRule:checkRules(14),
            timeArr:"",
            page:1,
            totalPage:1,
            pageSize:10
        }
    },
    created(){
        let now = formatTime(new Date) 
        ,str = now.split(" ")[0]
        let start = str.split("-"),
        start_time = `${start[0]}/${start[1]}/01 00:00:00`
        //console.log('start',start_time,new Date(start_time).getTime(),new Date(new Date().getTime()+24*60*60*1000))
        this.timeArr=[new Date(start_time),new Date(new Date().getTime()+24*60*60*1000)]
        //获取数据
        this.getStockCheck()
        this.searchWarehouse()
    },
    methods:{
        beforeCommand(row,status){
            return{
                row,
                status
            }
        },
        downloadCommand(e){
            console.log(e)
            this.download(e.row,e.status)
        },
        view(data){
            this.currentData = data
            this.showPop = true
        },
        //双击
        dbclick(row){
            console.log(row)
            this.view(row)
        },
        async getStockCheckList(pid){
            return new Promise((resolve,reject)=>{
                let params = {
                            method:"getStockCheckList",
                            pid
                        }
                        this.loading = true
                    this.$ajax.post(this.API.api,params).then((res)=>{
                        this.loading = false 
                        let list = res.data 
                        list.forEach(item=>{
                            if(item.imgs){
                                let images = []
                                item.imgs = JSON.parse(item.imgs)
                                item.imgs.forEach(it=>{
                                    images.push(it.url)
                                })
                                item.images = images
                            }
                        })
                         resolve(list)
                    }).catch(err=>{
                        reject(err)
                        this.loading = false 
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                    })  
            })
        },
        //删除
        handleDelete(pid){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                        let params = {
                            method:"delStockCheck",
                            pid
                        }
                        this.loading = true
                    this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false 
                            this.$message({
                                message: this.CONST.DEL_SUC,
                                type: 'success'
                            });
                            //刷新
                            this.getStockCheck()
                    }).catch(err=>{
                        this.loading = false 
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                    })  
                }).catch(err=>{
                    this.$message({
                        type: 'info',
                        message: err.msg||this.CONST.DEL_CANCEL
                    });      
                })
        },
         //搜索仓库
        searchWarehouse(key){
            //console.log(key)
            !key?key='':''
            let params = {
                method:"getWarehouse",
                key,
                page:1,
                pageSize:5
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data){
                    data.list.forEach(item=>{
                        item.label = item.name 
                        item.value = item.num
                    })
                    this.warehouseList = data.list
                }
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        changePage(page){
            this.page = page
            this.getStockCheck()
        },
        handleSelectionChange(e){
            console.log(e)
            this.selectList=e
            e.length<=0?this.showDel=false:this.showDel=true
        },
        //获取盘点列表
        getStockCheck(){
            if(this.totalPage<this.page){
                this.$message({
                        message: "已全部获取！",
                        type: 'warning'
                    });
                return
            }
//$start,$end,$page,$pageSize
            this.loading = true
            let params = {
                method:"getStockCheck",
                start:formatTime(this.timeArr[0]),
                end:formatTime(this.timeArr[1]),
                page:this.page,
                pageSize:this.pageSize,
                wid:this.wid
            }
            console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.totalPage = res.data.totalPage 
                this.list = res.data.list
                this.loading = false
                console.log(this.list)
            //console.log(list)
            }).catch(err=>{
                //err
                console.log(err.data)
                this.loading = false
                
            }) 
        },
        //搜索
        search(){
            this.page = 1
            this.totalPage = 1
            this.getStockCheck()
        },
        //删除列表
        deleteList(){

        },
        //下载
        download(row,status){
            //$firmid,$name,$wname,$wid,$checker,$reviewer,$remark,$pid,$time,$list,$picture
            this.loading = true
            this.getStockCheckList(row.pid).then(res=>{
                console.log(res)
                this.loading = true
                let params = row 
                params.method = "downloadStockCheckList"
                params.picture = status 
                params.list = JSON.stringify(res)
                this.$ajax.post(this.API.api,params).then(re=>{
                    console.log(re)
                    if(re.data.file){
                        let downurl = this.API.apiUrl +"download/"+re.data.file
                        window.open(downurl)
                    }
                }) 
            }).catch(err=>{
                this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            })
        }
    }
}
</script>
<style lang="less" scoped>
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
</style>