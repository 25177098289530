<template>
    <div class="detial">
        <el-table
            border
            fit
            width="100%"
            :data="list">
                <el-table-column
                label="序号"
                type="index"
                :loading="loading"
                :index="indexMethod">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="物料名称">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="编号">
                </el-table-column>
                <el-table-column
                    prop="number"
                    label="盘点数量">
                </el-table-column>
                <el-table-column
                    prop="specification_unit"
                    label="单位">
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="价格">
                </el-table-column>
                <el-table-column
                    width="120px"
                    label="图片附件">
                    <template slot-scope="scope">
                        <div class="img-box">
                            <el-image
                            v-for="(item,index) in scope.row.imgs"
                            :key="index"
                            style="width: 60px; height: 50px"
                            :src="item.url"
                            :preview-src-list="scope.row.images"
                            fit="fit"></el-image>
                        </div>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name:"StockDetail",
    props:{
        data:{
            type:Object
        }
    },
    data(){
        return{
            list:[],
            loading:false
        }
    },
    watch:{
        data:{
            handler(val){
                console.log(val.pid)
                this.getStockCheckList()
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        indexMethod(index) {
            return index * 1 + 1;
        },
        getStockCheckList(){
            let params = {
                            method:"getStockCheckList",
                            pid:this.data.pid
                        }
                        this.loading = true
                    this.$ajax.post(this.API.api,params).then((res)=>{
                        this.loading = false 
                        let list = res.data 
                        list.forEach(item=>{
                            if(item.imgs){
                                let images = []
                                item.imgs = JSON.parse(item.imgs)
                                item.imgs.forEach(it=>{
                                    images.push(it.url)
                                })
                                item.images = images
                            }
                        })
                         this.list=list
                         console.log(list)
                    }).catch(err=>{
                        this.loading = false 
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                    })  
        }
    }
}
</script>
<style lang="less" scoped>
.img-box{
    display: flex;
    .el-image{
        margin-right: 5px;
    }
}
</style>